var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.popupParam.waterTrustVolumeId
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.deleteData },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable:
                              _vm.editable &&
                              !_vm.popupParam.waterTrustVolumeId,
                            required: true,
                            type: "month",
                            label: "기준월",
                            name: "trustYm",
                          },
                          model: {
                            value: _vm.data.trustYm,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "trustYm", $$v)
                            },
                            expression: "data.trustYm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-plant", {
                          attrs: {
                            editable:
                              _vm.editable &&
                              !_vm.popupParam.waterTrustVolumeId,
                            required: true,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            editable:
                              _vm.editable &&
                              !_vm.popupParam.waterTrustVolumeId,
                            data: _vm.data,
                            deptValue: "deptCd",
                            type: "dept_user",
                            label: "작성부서 & 작성자",
                            name: "userId",
                          },
                          model: {
                            value: _vm.data.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "userId", $$v)
                            },
                            expression: "data.userId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "kg",
                            type: "number",
                            required: true,
                            editable: _vm.editable,
                            label: "폐수 전량위탁 처리량",
                            name: "trustVolume",
                          },
                          model: {
                            value: _vm.data.trustVolume,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "trustVolume", $$v)
                            },
                            expression: "data.trustVolume",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "kg",
                            type: "number",
                            required: true,
                            editable: _vm.editable,
                            label: "폐수 재활용 판매량",
                            name: "reVolume",
                          },
                          model: {
                            value: _vm.data.reVolume,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "reVolume", $$v)
                            },
                            expression: "data.reVolume",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            suffix: "kg",
                            type: "number",
                            required: true,
                            editable: _vm.editable,
                            label: "생산량",
                            name: "salesVolume",
                          },
                          model: {
                            value: _vm.data.salesVolume,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "salesVolume", $$v)
                            },
                            expression: "data.salesVolume",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }